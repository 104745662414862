<!-- 首页 -->
<template>
  <div class="huayang">
    <!-- 标题 -->
    <van-nav-bar
      title="中介超市"
      right-text="登录"
      @click-right="onClickRight"
    />
    <!-- 侧边栏 -->
    <van-sidebar
      v-model="activeKey"
      style="
        width: 25%;
        float: left;
        position: fixed;
        overflow-y: scroll;
        height: 100%;
      "
    >
      <van-sidebar-item title="项目咨询" />
      <van-sidebar-item title="项目环评" />
      <van-sidebar-item title="项目安评" />
      <van-sidebar-item title="文物勘探" />
      <van-sidebar-item title="工程设计" />
      <van-sidebar-item title="工程预算" />
      <van-sidebar-item title="工程担保" />
      <van-sidebar-item title="投资评审" />
      <van-sidebar-item title="招投标代理" />
      <van-sidebar-item title="施工监理" />
      <van-sidebar-item title="项目决算" />
      <van-sidebar-item title="项目审计" />
      <van-sidebar-item title="会计服务" />
      <van-sidebar-item title="税务服务" />
      <van-sidebar-item title="法务服务" />
      <van-sidebar-item title="人力资源服务" />
      <van-sidebar-item title="项目申报服务" />
      <van-sidebar-item title="企业上市服务" />
      <van-sidebar-item title="管委会融资服务" />
      <van-sidebar-item title="管委会代办服务" />
      <van-sidebar-item title="科研成果转化服务" />
    </van-sidebar>
    <!-- 内容 -->
    <div style="float: right; width: 75%">
      <div class="list">
        <router-link to="/huayang/company">
          <div
            style="
              float: left;
              display: inline-block;
              width: 90%;
              margin-left: 15px;
            "
          >
            <div style="font-size: 17px">智博建筑设计集团有限公司</div>
            <div style="font-size: 14px; margin: 5px 0px">
              <span style="color:#999"
                >成交量:<span style="color: #d81315; padding-left: 5px"
                  >68</span
                ></span
              >
              <span style="padding-left: 30px;color:#999"
                >好评率:<span style="color: #d81315; padding-left: 5px"
                  >95%</span
                ></span
              >
            </div>
            <div style="font-size: 12px; margin-top: 10px;" class="list_tabs">
              <span>特别好评</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="list">
        <router-link to="/huayang/company">
          <div
            style="
              float: left;
              display: inline-block;
              width: 90%;
              margin-left: 15px;
            "
          >
            <div style="font-size: 15px">洛阳城市建设勘测设计院有限公司</div>
            <div style="font-size: 14px; margin: 5px 0px">
              <span style="color:#999"
                >成交量:<span style="color: #d81315; padding-left: 5px"
                  >43</span
                ></span
              >
              <span style="padding-left: 30px;color:#999"
                >好评率:<span style="color: #d81315; padding-left: 5px"
                  >90%</span
                ></span
              >
            </div>
            <div style="font-size: 12px; margin-top: 10px" class="list_tabs">
              <span>特别好评</span>
              <span>售后服务</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="list">
        <router-link to="/huayang/company">
          <div
            style="
              float: left;
              display: inline-block;
              width: 90%;
              margin-left: 15px;
            "
          >
            <div style="font-size: 15px;color:#999">河南泰业资产评估有限公司</div>
            <div style="font-size: 14px; margin: 5px 0px">
              <span style="color:#999"
                >成交量:<span style="color: #d81315; padding-left: 5px"
                  >24</span
                ></span
              >
              <span style="padding-left: 30px;color:#999"
                >好评率:<span style="color: #d81315; padding-left: 5px"
                  >80%</span
                ></span
              >
            </div>
            <div style="font-size: 12px; margin-top: 10px" class="list_tabs">
              <span>服务周到</span><span>认真负责</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识大讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >企业信息</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeKey: 0,
      active: 2,
    };
  },
  methods: {
    onClickRight() {
      this.$router.push("/huayang/firmlogin");
    },
  },
};
</script>
<style>
.huayang .van-sidebar-item--select {
  color: #188DFA;
  background: #f8f9f9;
  font-size: 16px;
}
.huayang .van-sidebar-item--select::before {
  background-color: #188DFA;
}
.huayang .list {
  width: 90%;
  height: 85px;
  padding: 7px;
  background: #f0f1f3;
  border-radius: 10px;
  margin: 8px 16px 8px 8px;
}
.huayang .list img {
  width: 70px;
  height: 70px;
}
.huayang .list div {
  color: #000;
}
.list_tabs span {
  padding: 3px 8px;
  margin-right: 5px;
  color: #ff9e14;
  border: 1px solid #ff9e14;
  border-radius: 15px;
}
</style>